import { cn } from '../../../../lib/utils';
import { Variant, Intent } from '../Button';

const buttonClassNames = ({
  variant,
  intent,
  isActive,
}: {
  variant: Variant;
  intent: Intent;
  isActive: boolean;
}) => {
  const classes = {
    primary: {
      normal: cn({
        'bg-primaryBtnBackground hover:bg-primaryBtnBackgroundHover text-white focus:bg-primaryBtnBackgroundActive border-none':
          true,
        'bg-primaryBtnBackgroundActive': isActive,
      }),
      neutral: '',
      underline: '',
    },
    secondary: {
      normal: cn({
        'hover:bg-secondaryBtnBackgroundHover focus:bg-secondaryBtnBackgroundHover hover:text-eyebrowText border border-secondaryBtnBorder text-eyebrowText':
          !isActive,
        'bg-secondaryBtnBackgroundActive text-white border border-secondaryBtnBorder':
          isActive,
      }),
      neutral: '',
      underline: '',
    },
    filled: {
      normal: cn({
        'bg-secondaryBtnBackgroundActive hover:shadow-lg text-white focus:bg-secondaryBtnBackgroundActive border-none':
          true,
        'bg-primaryBtnBackgroundActive': isActive,
      }),
      neutral: '',
      underline: '',
    },
    outline: {
      normal: cn({
        'bg-transparent	border-2 border-white hover:bg-white hover:text-linkText text-white':
          true,
      }),
      neutral: '',
      underline: '',
    },
    dropdown: {
      normal: cn({
        'bg-transparent border-white hover:bg-gray-100 text-primary': true,
      }),
      neutral: '',
      underline: '',
    },
    dropdownGray: {
      normal: cn({
        'bg-gray-100 border-white rounded-full text-primary': true,
      }),
      neutral: '',
      underline: '',
    },
    input: {
      normal: cn({
        'bg-inputFieldDefaultBackground hover:bg-secondaryBtnBackgroundHover focus:bg-secondaryBtnBackgroundHover text-eyebrowText box-border border-2 border-transparent p-8':
          true,
        'border-primaryBtnBackground': isActive,
      }),
      neutral: '',
      underline: '',
    },

    link: {
      normal: cn(
        {
          'text-gray-700 hover:text-gray-800 hover:underline underline-offset-4 disabled:text-gray-300':
            !isActive,
          'underline underline-offset-4 hover:text-gray-600 hover:underline underline-offset-4':
            isActive,
        },
        'border-transparent'
      ),

      neutral: cn(
        {
          'text-white hover:white-gray-800 disabled:text-gray-300': !isActive,
          'text-slate-200 hover:text-gray-100 focus:text-gray-50 disabled:text-gray-600':
            isActive,
        },
        'border-transparent'
      ),
      underline: cn(
        {
          'text-gray-700 hover:text-gray-800 hover:underline underline-offset-[20px] hover:text-secondaryColour disabled:text-gray-300 ':
            !isActive,
          'underline underline-offset-[20px] hover:underline underline-offset-[20px] decoration-2 text-secondaryColour':
            isActive,
        },
        'border-transparent'
      ),
    },
    cancel: {
      normal: cn(
        {
          'bg-errorColour text-white py-2 px-4 rounded-full text-sm hover:bg-red-600 transition':
            !isActive,
          'text-white hover:white-gray-800 focus:text-gray-50 disabled:text-gray-600':
            isActive,
        },
        'border-transparent'
      ),
      neutral: cn(
        {
          'text-white hover:white-gray-800 disabled:text-gray-300': !isActive,
          'text-slate-200 hover:text-gray-100 focus:text-gray-50 disabled:text-gray-600':
            isActive,
        },
        'border-transparent'
      ),
      underline: '',
    },
    cancelOutline: {
      normal: cn({
        'bg-white text-white py-2 px-4 rounded-full text-sm hover:bg-gray-100 transition border border-errorColour':
          !isActive,
        'text-white hover:white-gray-800 focus:text-gray-50 disabled:text-gray-600':
          isActive,
      }),
      neutral: cn(
        {
          'text-white hover:white-gray-800 disabled:text-gray-300': !isActive,
          'text-slate-200 hover:text-gray-100 focus:text-gray-50 disabled:text-gray-600':
            isActive,
        },
        'border-transparent'
      ),
      underline: '',
    },
    dark: {
      normal: cn({
        'bg-black hover:bg-gray-800 text-white border-none': true,
        'bg-gray-800': isActive,
      }),
      neutral: '',
      underline: '',
    },
    grayDark: {
      normal: cn({
        'bg-gray-200 hover:bg-black hover:text-white text-black border-none': true,
        'bg-gray-800': isActive,
      }),
      neutral: '',
      underline: '',
    },
  };

  return classes[variant][intent];
};

export default buttonClassNames;
