import { cn } from '../../../../lib/utils';
import { Size } from '../Button';

const sizeClassNames = (
  size: Size,
  isIconButton: boolean,
  leftAlignText: boolean
) =>
  size === 'small'
    ? cn(' ', leftAlignText ? 'p-0' : isIconButton ? 'p-0' : ['p-0'])
    : size === 'normal'
      ? cn(
          'text-md',
          leftAlignText
            ? 'px-2 py-2'
            : isIconButton
              ? 'p-2'
              : ['px-4', 'py-4', 'h-9']
        )
      : size === 'large'
        ? cn(
            'text-base',
            leftAlignText
              ? 'px-4 py-3 md:py-4'
              : isIconButton
                ? 'p-2'
                : ['px-6 lg:px-8', 'py-3', 'h-9 lg:h-12']
          )
        : size === 'input'
          ? cn(
              'text-base',
              leftAlignText
                ? 'px-3 py-3 rounded rounded-l-full xl:min-w-[350px] xl:max-w-[450px] w-full'
                : isIconButton
                  ? 'm-0'
                  : ['px-10', 'py-6', ' h-12']
            )
          : size === 'extraLarge'
            ? cn(
                'text-base',
                leftAlignText
                  ? 'px-4 py-4 rounded rounded-l-full'
                  : isIconButton
                    ? 'p-2'
                    : ['px-8', 'py-7', ' h-12']
              )
            : size === 'dropdown'
              ? cn(
                  'text-base',
                  isIconButton ? 'p-2' : ['px-4', 'py-3', 'h-14 md:h-16'],
                  'w-full'
                )
              : '';
export default sizeClassNames;
