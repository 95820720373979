import { cn } from '../../../lib/utils';
import * as React from 'react';

type CardProps = React.HTMLAttributes<HTMLDivElement> & {
  variant?:
    | 'default'
    | 'fullRound'
    | 'withPicture'
    | 'review'
    | 'noRound'
    | 'roundedLarge'
    | 'noCardMobile';
  shadow?: boolean;
  shadowVariant?: 'small' | 'medium' | 'large' | 'normal';
  size?: 'extraSmall' | 'small' | 'medium' | 'large';
  overflow?: boolean;
};

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      className,
      variant = 'default',
      size = '',
      shadow = false,
      shadowVariant = 'normal',
      overflow = false,
      ...props
    },
    ref
  ) => {
    const sizeClasses =
      size === 'extraSmall'
        ? 'p-1'
        : size === 'small'
          ? 'p-2'
          : size === 'medium'
            ? 'p-4'
            : size === 'large'
              ? 'p-6'
              : '';

    const variantClasses = {
      default: 'rounded-3xl',
      review: 'rounded-3xl min-w-[250px] lg:min-w-0 transition-transform transform',
      fullRound: 'rounded-3xl xl:rounded-full relative',
      noRound: 'rounded-none',
      roundedLarge: 'rounded-lg',
      noCardMobile:
        'md:rounded-2xl shadow-lg md:py-0 md:p-6 p-0 md:bg-white bg-transparent border-none rounded-none md:shadow-[0_4px_10px_rgb(0,0,0,0.18)] shadow-none',
      withPicture:
        'rounded-3xl shadow border border-gray-200 overflow-hidden min-w-[300px] max-w-[300px] lg:min-w-full flex-shrink-0',
    };

    const shadowClasses: Record<'normal' | 'small' | 'medium' | 'large', string> = {
      normal: 'shadow-[0_8px_30px_rgb(0,0,0,0.12)]',
      small: 'shadow-[0_4px_10px_rgb(0,0,0,0.18)]',
      medium: 'shadow-md',
      large: 'shadow-lg shadow-[0_8px_30px_rgb(0,0,0,0.12)]',
    };

    return (
      <div
        ref={ref}
        className={cn(
          'border bg-card text-card-foreground',
          variantClasses[variant] || variantClasses['default'],
          sizeClasses,
          shadow ? shadowClasses[shadowVariant] : '',
          className,
          overflow ? '' : 'overflow-hidden'
        )}
        {...props}
      />
    );
  }
);
Card.displayName = 'Card';

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex flex-col space-y-1.5 p-2', className)}
    {...props}
  />
));
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn('text-2xl font-semibold leading-none tracking-tight', className)}
    {...props}
  />
));
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { fullHeight?: boolean }
>(({ className, fullHeight = false, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn('p-6 pt-0', fullHeight && 'h-full', className)}
      {...props}
    />
  );
});
CardContent.displayName = 'CardContent';

type CardContentRawProps = React.HTMLAttributes<HTMLDivElement> & {
  variant?: 'default' | 'compact' | 'spacious';
  middle?: boolean;
};

const CardContentRaw = React.forwardRef<HTMLDivElement, CardContentRawProps>(
  ({ className, variant = 'default', middle = false, ...props }, ref) => {
    const variantClasses = {
      default: 'py-2 px-2 flex items-center gap-2 lg:gap-3',
      compact: 'py-5 px-2 flex items-center gap-2 lg:gap-4',
      spacious: 'py-4 px-6 flex items-center gap-6 lg:gap-8',
    };

    return (
      <div
        ref={ref}
        className={cn(
          variantClasses[variant],
          middle ? 'justify-center' : '',
          className
        )}
        {...props}
      />
    );
  }
);
CardContentRaw.displayName = 'CardContentRaw';

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex items-center p-6 pt-0', className)}
    {...props}
  />
));
CardFooter.displayName = 'CardFooter';

export {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
  CardContentRaw,
};
