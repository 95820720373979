import React from 'react';

import getSpacingClassNames, {
  SpacingPropsType,
} from '../../helpers/getSpacingClassNames';
import stripSpacingProps from '../../helpers/stripSpacingProps';

import { Spinner } from '../spinner';
import baseClassNames from './helpers/baseClassNames';
import buttonClassNames from './helpers/buttonClassNames';
import sizeClassNames from './helpers/sizeClassNames';
import { Icon, IconName, IconColor, IconSize } from '../../../../../icons/src/icons';
import { cn } from '../../../lib/utils';

export type Intent = 'normal' | 'neutral' | 'underline';
export type Radius = 'normal' | 'rounded' | 'fullRounded' | 'roundedLeft';
export type Variant =
  | 'primary'
  | 'secondary'
  | 'filled'
  | 'link'
  | 'outline'
  | 'dropdown'
  | 'dropdownGray'
  | 'input'
  | 'dark'
  | 'cancel'
  | 'cancelOutline'
  | 'grayDark';

export type Size =
  | 'small'
  | 'normal'
  | 'large'
  | 'extraLarge'
  | 'input'
  | 'dropdown';

export type ButtonPropsType = {
  asDiv?: boolean;
  variant?: Variant;
  radius?: Radius;
  intent?: Intent;
  isActive?: boolean;
  rightIcon?: IconName;
  leftIcon?: IconName;
  icon?: IconName;
  isCenter?: boolean;
  fullWidth?: boolean;
  widthAuto?: boolean;
  fixedWidth?: boolean;
  leftAlignText?: boolean;
  loading?: boolean;
  size?: Size;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button' | undefined;
  iconColor?: IconColor;
  iconSize?: keyof typeof IconSize;
  iconLeftSize?: keyof typeof IconSize;
  noFlexSmallScreen?: boolean;
} & SpacingPropsType &
  Omit<
    React.HTMLAttributes<HTMLAnchorElement | HTMLButtonElement>,
    'className' | 'css'
  >;

const Button = (
  {
    asDiv = false,
    variant = 'primary',
    radius = 'rounded',
    intent = 'normal',
    isActive = false,
    icon,
    rightIcon,
    leftIcon,
    fullWidth,
    widthAuto,
    isCenter = false,
    leftAlignText = false,
    loading,
    size = 'normal',
    disabled,
    children,
    iconColor = 'dark',
    iconSize = 8,
    iconLeftSize = 6,
    noFlexSmallScreen = false,
    ...otherProps
  }: ButtonPropsType,
  // eslint-disable-next-line
  ref?: React.Ref<any>
) => {
  const spacingClassNames = getSpacingClassNames(otherProps);
  const htmlElementProps = stripSpacingProps(otherProps);

  const isIconButton = Boolean(icon && !rightIcon && !children);

  const className = cn(
    baseClassNames,
    buttonClassNames({ variant, intent, isActive }),
    spacingClassNames,
    sizeClassNames(size, isIconButton, leftAlignText),
    fullWidth && 'w-full',
    widthAuto && 'w-auto lg:w-full',
    'max-w-full',
    radius === 'normal'
      ? 'rounded-md'
      : radius === 'rounded'
        ? 'rounded-3xl'
        : radius === 'roundedLeft'
          ? 'rounded-r-lg rounded-l-full'
          : 'rounded-full'
  );

  const buttonChildren = (
    <>
      <div
        className={cn(
          noFlexSmallScreen
            ? 'block lg:flex lg:flex-1 justify-center items-center max-w-full'
            : 'flex justify-center items-center max-w-full',
          isCenter ? 'flex justify-center items-center max-w-full' : 'flex-1',
          loading && 'invisible'
        )}
      >
        {icon ? (
          <Icon
            name={icon}
            size={iconSize}
            marginRight={isIconButton ? undefined : 2}
            iconColor={iconColor}
          />
        ) : null}
        {leftIcon ? (
          <Icon name={leftIcon} size={iconLeftSize} iconColor={iconColor} />
        ) : null}

        <div
          className={cn(
            'inline-block align-middle w-full',
            leftAlignText ? 'flex justify-start pl-2 text-left' : 'text-center'
          )}
        >
          {children}
        </div>

        {rightIcon ? (
          <Icon
            name={rightIcon}
            size={iconSize}
            marginLeft={2}
            iconColor={iconColor}
          />
        ) : null}
      </div>
      {loading ? (
        <div className="absolute flex">
          <Spinner size={5} />
        </div>
      ) : null}
    </>
  );

  if (asDiv) {
    return <div className={className}>{buttonChildren}</div>;
  }

  return (
    <button
      ref={ref}
      type="button"
      {...htmlElementProps}
      disabled={disabled || loading}
      className={className}
    >
      {buttonChildren}
    </button>
  );
};

export default React.forwardRef(Button);
